define("discourse/plugins/f247-discourse-onesignal/discourse/controllers/onesignal-app-login", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({});

  _exports.default = _default;
});
define("discourse/plugins/f247-discourse-onesignal/discourse/onesignal-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.route("onesignal", function () {
      this.route("app-login");
    });
  }
});
define("discourse/plugins/f247-discourse-onesignal/discourse/routes/onesignal-app-login", ["exports", "@ember/runloop", "@ember/object/evented", "@ember/routing/route"], function (_exports, _runloop, _evented, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    afterModel: function afterModel(model, transition) {
      var _this = this;

      if (!this.currentUser) {
        (0, _runloop.next)(function () {
          return transition.send("showLogin");
        });
      } else {
        (0, _runloop.next)(function () {
          return _this.transitionTo("discovery.latest");
        });
      }
    },
    addBodyClass: (0, _evented.on)("activate", function () {
      if (!this.currentUser) {
        document.body.classList.add("mobile-app-login-modal");
      }
    }),
    removeBodyClass: (0, _evented.on)("deactivate", function () {
      if (!this.currentUser) {
        (0, _runloop.later)(function () {
          return document.body.classList.remove("mobile-app-login-modal");
        }, 300);
      }
    })
  });

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/onesignal-app-login"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mobile-app-login-intro\"],[8],[0,\"\\n  \"],[1,[28,\"i18n\",[\"onesignal.intro\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/onesignal-app-login"}});
define("discourse/plugins/f247-discourse-onesignal/initializers/component-overrides", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "onesignal-component-overrides",
    initialize: function initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", function (api) {
        api.modifyClass("component:d-modal", {
          pluginId: "discourse-onesignal",
          init: function init() {
            this._super.apply(this, arguments);

            if (document.body.classList.contains("mobile-app-login-modal")) {
              this.set("dismissable", false);
            }
          },
          mouseDown: function mouseDown(e) {
            if (document.body.classList.contains("mobile-app-login-modal")) {
              return;
            }

            this._super.apply(this, arguments);
          }
        });
      });
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/f247-discourse-onesignal/initializers/msg-rn-app", ["exports", "discourse/lib/ajax", "discourse/lib/utilities", "discourse/models/user"], function (_exports, _ajax, _utilities, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "msg-rn-app",
    after: "inject-objects",
    initialize: function initialize(container) {
      var currentUser = container.lookup("current-user:main");
      var capabilities = container.lookup("capabilities:main");

      if (capabilities.isAppWebview && currentUser) {
        (0, _utilities.postRNWebviewMessage)("currentUsername", currentUser.username);
      } // called by webview


      window.DiscourseOnesignal = {
        subscribeDeviceToken: function subscribeDeviceToken(token, platform, application_name) {
          (0, _ajax.ajax)("/onesignal/subscribe.json", {
            type: "POST",
            data: {
              token: token,
              platform: platform,
              application_name: application_name
            }
          }).then(function (result) {
            (0, _utilities.postRNWebviewMessage)("subscribedToken", result);
          });
        }
      };
    }
  };
  _exports.default = _default;
});

